import React from "react";

import styled from "styled-components";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const Contact = () => {
	return (
		<>
			<Navbar />
			<body></body>
			<Footer />
		</>
	);
};

export default Contact;
